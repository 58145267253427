export default {
  // name      : 'Search-extends',
  // components: {},
  // props     : [],
  data() {
    return {
      buttonList: [
        {
          name: 'RegistrationSearch',
          meta: {
            title: '报名查询',
          },
        },
        {
          name: 'ScoreSearch',
          meta: {
            title: '成绩查询',
          },
        },
      ],

      includeRouteNameList:[
        'RegistrationSearch',
        'ScoreSearch',
      ]
    }
  },
  // computed  : {},
  methods: {},
}
